import { React, useState } from "react";

import "./index.scss";
import Modal from "../Header/modal.jsx";
import { useEffect } from "react";
import axios from "axios";
import translit from "../../utils/translit";

const Footer = ({ tags = ["Федеральные новости", "Город", "Здравоохранение", "Социальные вопросы", "Транспорт", "Экономика", "Образование", "Наука и Технологии", "Культура", "Спорт", "Туризм", "СВО"] }) => {
    const [newsFormActive, setNewsFormActive] = useState(false);

    const [links, setLinks] = useState();

    useEffect(() => {
        let getLinks = axios.post("/getInfo", {}).then((data) => {
            setLinks(data.data);
        });
    }, []);

    return (
        <footer className="footer">
            <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
                <div itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
                    <img itemprop="url image" src="/icon512.png" alt="Петербург онлайн" title="Петербург онлайн" style={{display: 'none'}}/>
                </div>
                <meta itemprop="name" content="Петербург онлайн"/>
                <meta itemprop="telephone" content={links?.phone}/>
                <meta itemprop="address" content={links?.address}/>
            </div>
            <Modal open={newsFormActive} setOpen={setNewsFormActive} exit={"news"}></Modal>
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__main_content">
                        <a href="/" className="footer__logo_wrapper">
                            <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.38192 0.0837402H22.5756C25.0228 0.0837402 26.883 2.28275 26.4775 4.69612C26.4273 4.99469 26.3771 5.29327 26.3269 5.59193H23.1691C23.0169 4.14543 21.7871 2.97307 20.2428 2.97307H6.71582C4.89619 2.97307 3.51288 4.60053 3.81485 6.38615L5.4263 15.9127C5.66464 17.3221 6.89088 18.3542 8.32717 18.3542H18.6336C19.7081 18.3542 20.6648 17.7765 21.1786 16.8956H24.4272C24.3891 17.1212 24.3512 17.3469 24.3133 17.5727C23.993 19.478 22.3435 20.8735 20.4114 20.8735H6.54908C4.6174 20.8735 2.96801 19.4786 2.64742 17.5735L0.48017 4.69702C0.0738399 2.28347 1.93437 0.0837402 4.38192 0.0837402V0.0837402Z"
                                    fill="#2269F4"
                                />
                                <path d="M14.8834 12.8931L11.7841 14.6824C11.4391 14.8817 11.0078 14.6327 11.0078 14.2341V7.07665C11.0078 6.67837 11.4391 6.42929 11.7841 6.62843L14.8834 8.4179L17.9827 10.2072C18.3277 10.4064 18.3277 10.9045 17.9827 11.1037L14.8834 12.8931V12.8931Z" fill="#2269F4" />
                            </svg>
                        </a>
                        <div className="footer__links">
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Информация</h6>
                                </li>
                                <li>
                                    <ul>
                                        <li className="footer__link">
                                            <a href="/aboutus">О компании</a>
                                        </li>
                                        <li className="footer__link">
                                            <a href="/contacts">Контакты</a>
                                        </li>
                                        {/* <li className="footer__link">
                                            <a href="/redaction">Редакция</a>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Популярные теги</h6>
                                </li>
                                <li>
                                    <ul>
                                        {tags.map((tag, i) => {
                                            return (
                                                <li key={i} className="footer__link">
                                                    <a href={`/tag/${translit(tag)}`}>{tag}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list">
                                <li>
                                    <h6>Юридическая информация</h6>
                                </li>
                                <li>
                                    <ul>
                                        <li className="footer__link">
                                            <a href="/UserAgreement">Пользовательское соглашение</a>
                                        </li>
                                        <li className="footer__link">
                                            <a href="/PrivacyPolicy">Политика конфиденциальности</a>
                                        </li>
                                        <li className="footer__link">
                                            <a href="/SiteMaterials">Правила использования материалов сайта</a>
                                        </li>
                                        <li className="footer__link">
                                            <a href="/Cookies">Правила использования cookies</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="footer__links_list footer__links_list_socials">
                                <li className="footer__link_news">
                                    <button
                                        onClick={() => {
                                            setNewsFormActive(true);
                                        }}
                                    >
                                        Предложить новость
                                    </button>
                                </li>
                                {/* <li>
                                    <h6>Мы в соцсетях</h6>
                                </li>
                                <li>
                                    <ul>
                                        <li className="footer__link_social footer__link_social_tg">
                                            <noindex>
                                                <a rel='nofollow' href={links?.telegram}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M20.665 3.71694L2.935 10.5539C1.725 11.0399 1.732 11.7149 2.713 12.0159L7.265 13.4359L17.797 6.79094C18.295 6.48794 18.75 6.65094 18.376 6.98294L9.843 14.6839H9.841L9.843 14.6849L9.529 19.3769C9.989 19.3769 10.192 19.1659 10.45 18.9169L12.661 16.7669L17.26 20.1639C18.108 20.6309 18.717 20.3909 18.928 19.3789L21.947 5.15094C22.256 3.91194 21.474 3.35094 20.665 3.71694V3.71694Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </a>
                                            </noindex>
                                        </li>
                                        <li className="footer__link_social footer__link_social_ok">
                                            <noindex>
                                                <a rel='nofollow' href={links?.od}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.9861 12.341C9.16109 12.341 6.81309 9.995 6.81309 7.219C6.81309 4.347 9.16109 2 11.9871 2C14.9091 2 17.1601 4.346 17.1601 7.219C17.1551 8.58205 16.609 9.88733 15.642 10.848C14.675 11.8086 13.3661 12.346 12.0031 12.342L11.9861 12.341V12.341ZM11.9861 5.017C10.7901 5.017 9.88009 6.022 9.88009 7.22C9.88009 8.416 10.7901 9.326 11.9871 9.326C13.2321 9.326 14.0941 8.416 14.0941 7.22C14.0951 6.021 13.2321 5.017 11.9861 5.017ZM14.0461 16.603L16.9691 19.428C17.5441 20.049 17.5441 20.959 16.9691 21.534C16.3471 22.155 15.3881 22.155 14.9091 21.534L11.9871 18.661L9.16109 21.534C8.87409 21.821 8.49009 21.964 8.05809 21.964C7.72309 21.964 7.34009 21.82 7.00409 21.534C6.42909 20.959 6.42909 20.049 7.00409 19.427L9.97409 16.602C8.90152 16.2844 7.87262 15.8346 6.91109 15.263C6.19209 14.88 6.04909 13.923 6.43209 13.204C6.91109 12.486 7.77309 12.295 8.54009 12.774C9.57868 13.4078 10.7719 13.7431 11.9886 13.7431C13.2053 13.7431 14.3985 13.4078 15.4371 12.774C16.2041 12.295 17.1131 12.486 17.5441 13.204C17.9761 13.923 17.7831 14.879 17.1121 15.263C16.2031 15.838 15.1491 16.269 14.0471 16.604L14.0461 16.603V16.603Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </a>
                                            </noindex>
                                        </li>
                                        <li className="footer__link_social footer__link_social_vk">
                                            <noindex>
                                                <a rel='nofollow' href={links?.vk}>
                                                    <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M19.579 0.85495C19.719 0.38995 19.579 0.0489502 18.917 0.0489502H16.724C16.166 0.0489502 15.911 0.343951 15.771 0.667951C15.771 0.667951 14.656 3.38695 13.076 5.14995C12.566 5.66295 12.333 5.82495 12.055 5.82495C11.916 5.82495 11.714 5.66295 11.714 5.19795V0.85495C11.714 0.29695 11.553 0.0489502 11.088 0.0489502H7.642C7.294 0.0489502 7.084 0.30695 7.084 0.55295C7.084 1.08095 7.874 1.20295 7.955 2.69095V5.91895C7.955 6.62595 7.828 6.75495 7.548 6.75495C6.805 6.75495 4.997 4.02595 3.924 0.90195C3.715 0.29495 3.504 0.0499506 2.944 0.0499506H0.752C0.125 0.0499506 0 0.344951 0 0.668951C0 1.25095 0.743 4.13095 3.461 7.93995C5.273 10.541 7.824 11.951 10.148 11.951C11.541 11.951 11.713 11.638 11.713 11.0979V9.13195C11.713 8.50595 11.846 8.37995 12.287 8.37995C12.611 8.37995 13.169 8.54395 14.47 9.79695C15.956 11.283 16.202 11.95 17.037 11.95H19.229C19.855 11.95 20.168 11.6369 19.988 11.0189C19.791 10.4039 19.081 9.50895 18.139 8.44995C17.627 7.84595 16.862 7.19595 16.629 6.87095C16.304 6.45195 16.398 6.26695 16.629 5.89495C16.63 5.89595 19.301 2.13395 19.579 0.85495V0.85495Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </a>
                                            </noindex>
                                        </li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="footer__licence">© АО «Онлайн-СПБ», 2015–2022. Сообщения и материалы сетевого издания (зарегистрировано Федеральной службой по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) 03.12.2021 за номером ЭЛ №ФС77-282718)</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
